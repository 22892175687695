import React,{useState, useEffect} from 'react';
import { useParams } from 'react-router';
import emailjs from 'emailjs-com'
import Menu from '../../components/Menu';
import db from '../../components/Server';

import { Container, Wrapper, Form, CarWrapper, Car } from './styles';

function Reserve() {

  const {id} = useParams()

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [tel, setTel] = useState('');

  const [car, setCar] = useState([])
  const [model, setModel] = useState('')
  const [brand, setBrand] = useState('')
  const [year, setYear] = useState('')
  const [image, setImage] = useState('')
  const [disp, setDisp] = useState('')
  const [price, setPrice] = useState('')

  function sendForm(e){

    console.log(`${name} ${email} ${cpf} ${tel}`)

    e.preventDefault();

    if( name === '' || cpf === '' || email === '' || tel === ''){
      alert('Por favor preencha os campos corretamente!')
    }else{

      emailjs.sendForm('service_vjqrd7g', 'template_xbfksaf', e.target ,'user_KTQdFYNZ3WFqJqKhy31n7')
      .then((result) => {
        alert('Pedido enviado com sucesso!')

        setTel('')
        setName('')
        setEmail('')
        setCpf('')

        console.log(result.text)

      }, (error)=>{
        console.log(error.text)
      })
    }

  }

  useEffect(()=>{

    const carRef = db.collection('carros').doc(id)

    async function loadCar(){
      await carRef.get().then((doc) => {

        if(doc.exists){
          
          let car = []

          car.push(doc.data())

          setCar(car)
          setModel(doc.data().model)
          setBrand(doc.data().brand)
          setYear(doc.data().year)
          setImage(doc.data().i360)
          setDisp(doc.data().disp)
          setPrice(doc.data().price)

        }else{
          console.log('carro não encontrado')
          alert('carro não existe no banco de dados')
        }

      }).catch((error) => {
        alert('ouve um erro: ' + error)
        console.log(error)
      })
    }

    loadCar()

  }, [])

  return(
    <>
      {/* <Menu/> */}
      <Container>
        
        <Wrapper>

        <h1>fazer reserva</h1>
          <Form onSubmit={sendForm}>

            <input 
            name='name'
            value={name}
            onChange={e => setName(e.target.value)}
            type='text'
            placeholder='Nome completo*'
            />

            <input 
            name='email'
            value={email}
            onChange={e => setEmail(e.target.value)}
            type='email'
            placeholder='E-mail*'
            />

            <input 
            name='cpf'
            value={cpf}
            onChange={e => setCpf(e.target.value)}
            type='text'
            autoComplete={false}
            placeholder='CPF*'
            />

            <input 
            name='tel'
            value={tel}
            onChange={({target}) => setTel(target.value)}
            type='tel'
            autoComplete={false}
            placeholder='Telefone(Whatsapp)*'
            />

            <CarWrapper>
              <h2>carro escolhido</h2>

              {
                car.map(()=>{
                  return(
                    <li>
                      <Car>
                        <iframe src={image}/>
                        <div>
                          <h2 >{brand} {model} {year}</h2>
                          <p className={disp === true ? 'disp' : 'noDisp'}>{disp === true ? 'disponivel' : 'indisponivel'}</p>
                          <h3 className={disp === true ? '' : 'noPrice'}>{price}</h3>
                        </div>
                      </Car>
                    </li>
                  )
                })
              }

            </CarWrapper>

            <input type="submit" value='Finalizar pedido' className='send_btn'/>
          </Form>
        </Wrapper>
      </Container>
    </>
  );
}

export default Reserve;