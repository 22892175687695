import firebase from "firebase";
import ('firebase/firestore')

const apiKey = "AIzaSyDyjCFfDAmANDxJ0H_W6pwD5dNjYQqjCcg"
const authDomain = "goodca-database.firebaseapp.com"
const projectId = "goodca-database"

firebase.initializeApp({
  apiKey: apiKey,
  authDomain: authDomain,
  projectId: projectId
})

const db = firebase.firestore()

export default db