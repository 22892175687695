import { createGlobalStyle } from "styled-components";

export const  GlobalStyles = createGlobalStyle`

*{
  margin: 0;
  padding: 0;
  color: #fff;
  box-sizing: border-box;
}
html{
  background-color: var(--primary);

  font-family: 'Franklin Gothic Medium',
  'Arial Narrow', Arial, sans-serif;
}

*,button, input{
  border-color: transparent;
  background: none;
  outline: none;
}
button{
  cursor: pointer;
}
ul{
  list-style: none;
}

:root{
  --primary: #14283d;
  --secondary: #ffe400;
  --dark_bars: #09131f;
  --light_bars: #0069d2;
  --white: #ffffff;
}

`