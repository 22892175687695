import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import Reserve from '../../pages/Reserve';

function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/reserve/car/:id' component={Reserve}/>
      </Switch>
    </BrowserRouter>
  );
}

export default Router;