import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 0 auto;
  padding: 0 auto;
  max-width: 800px;
`;
export const Wrapper = styled.div`

	h1{
		margin: 5px;
		padding: 0 10px;
		border-bottom: 2px solid var(--secondary);
	}

  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;

`
export const Form = styled.form`

  display: flex;
	flex-direction: column;

	margin-top: 30px;



	input{
		font-size: 18px;
		margin-bottom: 15px;
		background-color: white;
		width: 90vw;
		height: 50px;
		padding: 5px;
		border-radius: 5px;
		color: black;

		:focus{
			border: 3px solid var(--secondary);
		}
	}

	.send_btn{
		margin-top: 15px;
		background-color: var(--white);
		border: 2px solid var(--dark_bars);

		:hover{
			background-color: var(--secondary);
		}
	}

`
export const CarWrapper = styled.div`

	max-width: 800px;
	margin: 0 auto;
	list-style: none;

	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: center;

	h2{
		font-size: 30px;
		border-bottom: 2px solid var(--secondary);
		padding: 0 5px;
		align-self: flex-start;
	}

`
export const Car = styled.div`

	margin-top: 15px;
	background-color: whitesmoke;
	width: 80vw;
	max-width: 80vw;
	padding: 10px;
	height: max-content;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 15px;

	div{
		align-self: center;

		.disp{
			background-color: green;
		}
		.noDisp{
			background-color: brown;
		}
		p{
			color: white;
			font-size: 20px;
			font-weight: bolder;
			text-align: center;
			padding: 5px 0;
		}
	}

	h2{
		color: black;
		font-size: 25px;
		border-bottom: none;
	}

	h3{
		color: black;
		text-align: center;
		font-size: 25px;
	}
	.noPrice{
		display: none;
	}

	iframe{
		background-color: var(--dark_bars);
		border-radius: 10px;
		width: 80%;
		height: 40vw;
	}

`