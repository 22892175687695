import React from "react";
import Router from './components/Router'
import {GlobalStyles} from './components/GlobalStyles'
function App() {
  return (
    <div className="App">
      <Router />
      <GlobalStyles />
    </div>
  );
}

export default App;
