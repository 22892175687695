import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import LogoShort from '../../images/logo-short.png'
import { Container, Wrapper, NavWrapper } from './styles';

import { GoThreeBars, GoX, GoSearch } from 'react-icons/go';

const links = [
    {route: "https://www.goodcarhouse.com.br/", label: "Site"},
    {route: "https://www.goodcarhouse.com.br/sobre", label: "Sobre"},
    {route: "https://www.goodcarhouse.com.br/contato", label: "Contato"}
]

function Menu() {

    const [openMenu, setOpenMenu] = useState(false)

    const rederLink = () => {

        return(
            links.map( link =>
                <a href={link.route} className='link-menu'>
                    {link.label}
                </a>
            )
        )
    }

    const HanddleOpen = () => {
        setOpenMenu(!openMenu)
    }

  return (
      <>

        <Wrapper className = {openMenu !== true ? '' : 'open' }>

            <button className='btn-menu' onClick={HanddleOpen}>
                {
                openMenu !== true ?
                <GoThreeBars size='30' className='menu-bars'/>:
                <GoX size='30' className='menu-bars'/>
                }
                
            </button>



            <a  href="/"><img className='logoShort' src={LogoShort} alt='logo'/></a>

           <NavWrapper className={openMenu !== true ? '' : 'open'}>
            <ul>
                <li>{rederLink()}</li>
            </ul>
           </NavWrapper>

        </Wrapper>
    </>
  );
}

export default Menu;