import styled from 'styled-components';

export const Container = styled.div`

  display: flex;
  justify-content: center;

  img{

    width: 98vw;
    height: auto;
  }
  background-color: var(--light_bars);

`;

export const Wrapper = styled.div`

&.open{
  overflow: hidden;
}

.logoShort{
  width: 80px;
  float: right;
  margin-top: -40px;
  display: none;
}

overflow-block: inherit;

background-color: #09131f;
border-bottom: 3px solid var(--secondary);

  display: flex;
  padding: 15px;
  align-items: center;
  justify-content: center;
  font-size: 20px;

  .btn-menu{
    display: none;
    margin: 0;
    padding: 0;
  }

  .search_area{

    .search-btn{
    border: 3px solid white;
    border-radius: 50%;
    padding: 10px;
    padding-bottom: 5px;

    :hover{
      border-color: var(--secondary);
    }
  }
  
  }



  display: block;

  .search_area{
    margin-top: 15px ;

    .search-btn{
      margin-left: -5px;
  }

  }

  .logoShort{
    display: block;
  }

  .btn-menu{
    display: block;
    background-color: transparent;
    border: none;
  }


`
export const NavWrapper = styled.nav`


  ul{
    display: flex;
    list-style: none;
    gap: .5rem;
  }


  &.open{
    ul{
      height: 100%;
      visibility: visible;
      overflow-y: auto;
      overflow: hidden;
    }
  }

    ul{
      display: block;
      position: absolute;
      width: 100%;
      right: 0;
      height: 0;
      background-color: #09131f;
      z-index: 10000;
      transition: .6s;
      visibility: hidden;
      overflow-y: hidden;

      .link-menu{
        display: block;
        margin: 0 15px;
        padding: 15px 0;
        border-bottom: 1px solid var(--secondary);
        &:last-child{
          border: none;
        }
      }

    }

  .link-menu{
    margin: 0 15px;
    text-decoration: none;
    display: inline-block;
    &:hover{
      color: var(--secondary);
    }
  }

`